export const initGoogleAnalytics = gtagId => {
  if (!gtagId) {
    console.warn('Google Analytics ID is missing.');
    return;
  }

  // Load Google Analytics script dynamically
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  document.head.appendChild(script);

  // Ensure gtag is globally available after script is loaded
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', gtagId);
  };
};
